@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Mukta", sans-serif;
}

.App{
  background-color:#098009 ;
}

